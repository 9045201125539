import styled from 'styled-components';
import THEME from '../../../styles/theme';

interface ILinkProps {
    label: string
    href: string
    subLinks?: ILinkProps[]
}

interface IMenuProps {
    links: ILinkProps[]
    active?: string
    type?: string
    subType?: string
}

interface IMenuContainerProps {
    type?: string
}

export type { IMenuProps }

function Menu(props: IMenuProps) {
    return (
        <MenuBox>
            <MenuContainer type={props.type}>
                <EmptyBloc></EmptyBloc>
            </MenuContainer>
        </MenuBox>
    );
}

export default Menu;

const MenuBox = styled.div`
    position: fixed;
    z-index: 1;
    height: 78px;
    width: 100vw;
    display: flex;
    flex-direction: column;
`;

const EmptyBloc = styled.div`
    padding: 33px;
    width: 100px;
`;

const MenuContainer = styled.div<IMenuContainerProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${THEME.text.font};
    background-color: ${props => 
        props.type === 'general' ? THEME.background.color.general : 
        props.type === 'main' ? THEME.background.color.main : 
        props.type === 'primary' ? THEME.background.color.primary : 
        props.type === 'principal' ? THEME.background.color.principal : 
        props.type === 'secondary' ? THEME.background.color.secondary : 
        props.type === 'diverse' ? THEME.background.color.diverse : 
        props.type === 'terciary' ? THEME.background.color.terciary : 
        THEME.background.color.general};
    box-shadow: rgba(0, 0, 0, 0.08) 0 3px 10px 0;
`
